import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AttService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getAttServiceById(id: string) {
    return this.http.get(`${this.apiService.apiUrl}/att_getService/${id}`);
  }

  getAttServiceSpecificationById(id: string) {
    return this.http.get(`${this.apiService.apiUrl}/att_getServiceSpecification/${id}`);
  }

  postAttQuote(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/att_makeQuote`, body);
  }

  getAttQuoteById(id: string) {
    return this.http.get(`${this.apiService.apiUrl}/att_getQuote/${id}`);
  }

  // ACC
  getAttAccPricingDb() {
    return this.http.get(`${this.apiService.apiUrl}/getAttAccPricingDb`);
  }

  // ACC COAX
  insertAccCoaxOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/insertAccCoaxOffer`, body);
  }

  editAccCoaxOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/editAccCoaxOffer`, body);
  }

  deleteAccCoaxOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/deleteAccCoaxOffer`, body);
  }

  // ACC FIBER
  insertAccFiberOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/insertAccFiberOffer`, body);
  }

  editAccFiberOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/editAccFiberOffer`, body);
  }

  deleteAccFiberOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/deleteAccFiberOffer`, body);
  }

  //Acc optional extra

  insertAccCoaxExtrasOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/insertAccCoaxExtrasOffer`, body);
  }
  
  editAccCoaxExtrasOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/editAccCoaxExtrasOffer`, body);
  }

  deleteAccCoaxExtrasOffer(body: any) {
    return this.http.post(`${this.apiService.apiUrl}/deleteAccCoaxExtrasOffer`, body);
  }

}
